<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="350"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          icon
          v-bind="attrs"
          v-on="on"
      >
        <v-icon>mdi-folder-plus-outline</v-icon>
      </v-btn>
    </template>
    <v-card :loading="processing" :disabled="processing">
      <v-card-title>
        Nova Pasta
      </v-card-title>
      <v-card-text>
        <my-text-field
          v-model="folderName"
          autofocus
          ref="folderName"
          @keydown.native.enter="createNewFolder"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
            text
            @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
            color="primary"
            :disabled="!folderName"
            depressed
            @click="createNewFolder"
        >
          Criar
        </v-btn>
      </v-card-actions>
    </v-card>
    <custom-snackbar ref="snackbar" />
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import MyTextField from "@/view/components/MyTextField.vue";

export default {
  name: "FileManagerNewFolder",

  components: {MyTextField},

  props: {
    path: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    processing: false,
    folderName: '',
  }),

  methods: {
    async createNewFolder() {
      if (this.processing) {
        return
      }

      try {
        this.processing = true

        await ApiService._post('file-manager', {
          path: this.path,
          name: this.folderName
        }, {
          snackbar: this.$refs.snackbar
        })

        this.$emit('success')

        this.folderName = null

        this.dialog = false
      } finally {
        this.processing = false
      }
    }
  },
};
</script>