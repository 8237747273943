<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="350"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs"></slot>
    </template>
    <v-card :loading="processing">
      <v-card-title>
        Excluir
      </v-card-title>
      <v-card-text class="title">
        Esta ação não poderá ser desfeita. Deseja continuar?
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
            text
            @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
            color="primary"
            depressed
            @click="execute"
        >
          Sim
        </v-btn>
      </v-card-actions>
    </v-card>
    <custom-snackbar ref="snackbar" />
  </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import MyTextField from "@/view/components/MyTextField.vue";

export default {
  name: "FileManagerDelete",

  components: {MyTextField},

  props: {
    path: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    processing: false,
  }),

  watch: {
    dialog (value) {
      if (!value) {
        this.newName = null
        this.$emit('closeMenu')
      }
    }
  },

  methods: {
    async execute () {
      this.processing = true

      try {
        await ApiService._delete('file-manager', {
          params: {
            path: this.path,
          },
          snackbar: this.$refs.snackbar,
        })

        this.$emit('success')

        this.dialog = false
      } finally {
        this.processing = false
      }
    },
  },
};
</script>