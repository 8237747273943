<template>
  <my-detail-dialog
    :disabled="disabled"
    icon="mdi-list-status"
    :resource="`projeto-pesquisa/acompanhamento/encerrar-projeto/${itemId}`"
    title="Termo de Encerramento de Projeto"
    tooltip="Encerramento"
    :width="768"
    @validate="errors = $event"
    @success="$emit('success', $event)"
  >
    <template v-slot:body="{ item }">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <my-select
                v-model="item.situation"
                :error-messages="errors.situation"
                label="Situação"
                :filter="[6, 7]"
                required
                resource="projeto-pesquisa/situacoes"
                return-object
            />
          </v-col>
          <v-col cols="12" md="4">
            <my-text-field
                v-model="item.closed_at"
                :error-messages="errors.closed_at"
                label="Data do Evento"
                mask="##/##/####"
                placeholder="dd/mm/aaaa"
                required
                type="date"
            />
          </v-col>
          <v-col cols="12">
            <my-text-area
                v-model="item.closing_justification"
                :error-messages="errors.closing_justification"
                label="Justificativa"
                required
            />
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </my-detail-dialog>
</template>
<script>
import MyDetailDialog from "@/view/components/MyDetailDialog.vue";
import MyTextArea from "@/view/components/MyTextArea.vue";
import MyTextField from "@/view/components/MyTextField.vue";
import MySelect from "@/view/components/MySelect.vue";
import MyForm from "@/view/components/MyForm.vue";

export default {
  name: "PesquisaAcompanhamentoEncerramentoFormDialog",

  components: {MyForm, MySelect, MyTextField, MyTextArea, MyDetailDialog},

  props: {
    itemId: {
      type: [Number, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    errors: {},
    form: {
      situation: null,
      approve_committee_at: null,
      approve_justification: null,
    },
  }),
}
</script>