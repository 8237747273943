<template>
  <my-detail-dialog
    :hide-default-actions="hideDefaultActions"
    :resource="`projeto-pesquisa/solicitacao/${itemId}`"
    :title="`Detalhes da Solicitação de Projeto #${itemId}`"
    :width="1024"
    hide-default-actions
  >
    <template v-slot:body="{ item }">
      <v-card-title>
        Dados do Projeto
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="9">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Nome
              </v-col>
              <v-col cols="12">
                {{ item.project_name }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="3">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Situação
              </v-col>
              <v-col cols="12">
                {{ item.situation ? item.situation.description : ''}}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>
        Solicitante
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="5">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Nome
              </v-col>
              <v-col cols="12">
                {{ item.owner_name }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="3">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Cargo
              </v-col>
              <v-col cols="12">
                {{ item.owner_position }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="4">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                E-mail
              </v-col>
              <v-col cols="12">
                {{ item.owner_email }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>
        Parceiro
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Nome
              </v-col>
              <v-col cols="12">
                {{ item.partner_name }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Contato
              </v-col>
              <v-col cols="12">
                {{ item.partner_contact }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Telefone
              </v-col>
              <v-col cols="12">
                {{ item.partner_phone }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="4">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Site
              </v-col>
              <v-col cols="12">
                {{ item.partner_site }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>
        Detalhes do Projeto
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="subtitle-2">
            Descrição
          </v-col>
          <v-col cols="12">
            {{ item.tsp? item.tsp.description : '' }}
          </v-col>
          <v-col cols="12" class="subtitle-2">
            Objetivo
          </v-col>
          <v-col cols="12">
            {{ item.tsp? item.tsp.goal : '' }}
          </v-col>
          <v-col cols="12" class="subtitle-2">
            Informações Adicionais
          </v-col>
          <v-col cols="12">
            {{ item.tsp? item.tsp.additional_information : '' }}
          </v-col>
        </v-row>
      </v-card-text>
    </template>
  </my-detail-dialog>
</template>
<script>
import MyDetailDialog from "@/view/components/MyDetailDialog.vue";

export default {
  name: "PesquisaSolicitacaoProjetoDetailDialog",

  components: {MyDetailDialog},

  props: {
    itemId: {
      type: [Number, String],
      required: true,
    },
    hideDefaultActions: {
      type: Boolean,
      default: false,
    },
  },
}
</script>