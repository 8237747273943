<template>
  <v-dialog
      v-model="dialog"
      :width="width"
      persistent
  >
    <template #activator="{ on: onDialog }">
      <v-tooltip :left="tooltipLeft" :right="tooltipRight||!tooltipLeft&&!tooltipRight">
        <template #activator="{ on: onTootip }">
          <v-btn
            :disabled="disabled"
            icon
            small
            v-on="{ ...onDialog, ...onTootip }"
          >
            <v-icon>
              {{ icon }}
            </v-icon>
          </v-btn>
        </template>
        {{ tooltip }}
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        {{ title }}
        <slot name="title" :item="item"></slot>
        <v-spacer />
        <v-btn
            icon
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <slot name="body" :item="item" />
      <v-card-actions v-if="!hideDefaultActions">
        <v-spacer />
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
            color="primary"
            depressed
            @click="save()"
        >
          Salvar
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
    <custom-snackbar ref="snackbar" />
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "MyDetailDialog",

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDefaultActions: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'mdi-open-in-new',
    },
    resource: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      default: 'Detalhes',
    },
    tooltipLeft: {
      type: Boolean,
      default: false,
    },
    tooltipRight: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 500,
    },
  },

  data: () => ({
    dialog: false,
    item: {}
  }),

  watch: {
    dialog(val) {
      if (val === true) {
        this.getDetail()
        this.$emit('onActivate')
      }
    },
  },
  methods: {
    async getDetail() {
      if (this.resource === null) {
        return
      }

      this.item = (await this.$http.get(this.resource)).data
    },

    async save() {
      this.formProcessing = true

      this.$emit('validate', [])

      try {
        const {data} = await ApiService.put(this.resource, this.item)

        this.$refs.snackbar.show(
            'Tudo certo!',
            'Operação realizada com sucesso',
            'success',
            '2000'
        )

        this.$emit('success', data)

        this.dialog = false
      } catch (e) {
        if (e.response) {
          if (e.response.status === 422) {
            this.$emit('validate', e.response.data.errors)

            this.$refs.snackbar.show(
                'Desculpe, algo deu errado!',
                'Verifique os campos em vermelho e tente novamente',
                'danger',
                3000
            )
          } else if (e.response.data.message) {
            this.$refs.snackbar.show(
                'Desculpe, algo deu errado!',
                e.response.data.message,
                'danger',
            )
          } else {
            this.$refs.snackbar.show(
                'Desculpe, algo deu errado!',
                'Tente novamente mais tarde',
                'danger',
            )
          }
        } else {
          this.$refs.snackbar.show('Desculpe, algo deu errado!', e, 'danger')
        }
      }

      this.formProcessing = false
    },
  },
}
</script>