<template>
  <my-detail-dialog
    :disabled="disabled"
    icon="mdi-paperclip"
    title="Repositório de Arquivos do Projeto"
    tooltip="Arquivos"
    :width="768"
    @validate="errors = $event"
    @success="$emit('success', $event)"
    @onActivate="init()"
    hide-default-actions
    ref="dialog"
  >
    <template #body>
      <v-card-text>
        <v-card-title class="py-0">
          <!--
          <v-btn
              :disabled="currentItem === items"
              icon
              @click="navigateBack(currentItem.children[0])"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          -->
          <v-spacer />

          <v-btn
              :disabled="currentItem === items"
              icon
              @click="navigateHome()"
          >
            <v-icon>mdi-home</v-icon>
          </v-btn>

          <v-btn
              icon
              @click="load(currentItem.id)"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>

          <file-manager-new-folder
              :path="currentPath"
              @success="load(currentItem.id)"
          />

          <input type="file" ref="inputfile" style="display: none" @change="uploadFromInputfile" />
          <v-btn
              icon
              @click="$refs.inputfile.click()"
          >
            <v-icon>mdi-upload</v-icon>
          </v-btn>
        </v-card-title>
        <v-card
          class="v-card--link"
          outlined
          :ripple="false"
          style="cursor: default;"
          @click.left.prevent=""
          @click.right.prevent=""
          @drop.prevent="onDrop($event)"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
        >
          <v-data-table
            dense
            disable-pagination
            disable-filtering
            :headers="headers"
            height="500"
            hide-default-footer
            :items="computedCurrentItem"
            :loading="loading"
            @dblclick="openItem"
          >
            <template
                v-slot:body="{ items }"
            >
              <tbody>
                <template v-for="(item) in items">
                  <v-menu
                    v-model="item.menu"
                    absolute
                    :position-x="item.menu_x"
                    :position-y="item.menu_y"
                    transition="none"
                  >
                    <v-list dense>
                      <v-list-item dense @click="openItem($event, { item })">
                        <v-list-item-title class="" @click="">
                          <v-icon left small>mdi-folder-open-outline</v-icon>
                          Abrir
                        </v-list-item-title>
                      </v-list-item>
                      <file-manager-delete
                        :path="item.path"
                        @closeMenu="item.menu = false"
                        @success="load(currentItem.id)"
                      >
                        <template #default="{ on, attrs}">
                          <v-list-item
                            dense
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-title>
                              <v-icon left small>mdi-trash-can-outline</v-icon>
                              Excluir
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </file-manager-delete>
                      <file-manager-rename
                        :old-name="item.name"
                        :file="item.file"
                        :from="item.path"
                        :path="currentPath"
                        @closeMenu="item.menu = false"
                        @success="load(currentItem.id)"
                      >
                        <template #default="{ on, attrs}">
                          <v-list-item
                            dense
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-list-item-title>
                              <v-icon
                                left
                                small
                              >
                                mdi-form-textbox
                              </v-icon>
                              Renomear
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                      </file-manager-rename>
                    </v-list>
                  </v-menu>
                  <tr
                    @click.left.prevent="closeMenu($event, { item })"
                    @click.right.prevent="openMenu($event, { item })"
                    @dblclick="openItem($event, { item })"
                    class="prevent-select"
                    style="cursor: default;"
                  >
                    <td>
                      <v-icon small>
                        {{ !item.file ? (item.id !== '..' ? 'mdi-folder-outline' : 'mdi-chevron-left') : fileIcons[item.file] || 'mdi-file' }}
                      </v-icon>
                    </td>
                    <td v-if="item.id !== '..'">{{ item.name }}</td><td v-else></td>
                    <td v-if="item.id !== '..'">{{ item.type }}</td><td v-else></td>
                    <td v-if="item.id !== '..'">{{ item.updated_at | datetime }}</td><td v-else></td>
                    <td v-if="item.id !== '..'">{{ item.size | formatBytes }}</td><td v-else></td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-data-table>
          <v-fade-transition>
            <v-overlay
                v-if="dragover"
                absolute
                color="grey lighten-2"
            >
              <v-row class="d-flex flex-column fill-height" dense align="center" justify="center">
                <v-icon color="grey" size="128">
                  mdi-cloud-upload
                </v-icon>
                <p class="title grey--text">
                  Solte os arquivos aqui para fazer o envio
                </p>
              </v-row>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </v-card-text>
    </template>
  </my-detail-dialog>
</template>
<script>
import MyDetailDialog from "@/view/components/MyDetailDialog.vue"
import MyTextArea from "@/view/components/MyTextArea.vue"
import MyTextField from "@/view/components/MyTextField.vue"
import MySelect from "@/view/components/MySelect.vue"
import MyForm from "@/view/components/MyForm.vue"
import ApiService from "@/core/services/api.service"
import FileManagerRename from "@/view/components/FileManager/Rename.vue";
import FileManagerDelete from "@/view/components/FileManager/Delete.vue";
import FileManagerNewFolder from "@/view/components/FileManager/NewFolder.vue";
import Snackbar from "@/core/components/Snackbar.vue";

export default {
  name: "FileManager",

  components: {
    Snackbar,
    FileManagerNewFolder,
    FileManagerDelete,
    FileManagerRename,
    MyForm, MySelect, MyTextField, MyTextArea, MyDetailDialog},

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    folder: {
      type: String,
      required: true,
    },
    repository: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    errors: {},
    loading: true,
    dragover: false,
    files: [],
    headers: [
      { value: 'icon', width: 0 },
      { text: 'Nome', value: 'name' },
      { text: 'Tipo', value: 'type' },
      { text: 'Data de Modificação', value: 'updated_at' },
      { text: 'Tamanho', value: 'size' },
    ],
    fileIcons: {
      png: 'mdi-file-image',
      jpg: 'mdi-file-image',
      jpeg: 'mdi-file-image',
      pdf: 'mdi-file-pdf-box',
      doc: 'mdi-file-word-box',
      docx: 'mdi-file-word-box',
      xls: 'mdi-file-excel-box',
      xlsx: 'mdi-file-excel-box',
    },
    items: [],
    currentPath: null,
    currentItem: []
  }),

  computed : {
    computedCurrentItem () {
      return this.removeSystemFiles(this.currentItem.children || []);
    }
  },

  methods: {
    init() {
      this.items = []

      this.$nextTick(() => {
        this.load()
      })
    },

    async load(parent_id = null) {
      try {
        this.loading = true;

        this.items = (
            await ApiService._get(`file-manager`, {
              params: {
                path: `${this.repository}/${this.folder}`
              },
              snackbar: this.$refs.dialog.$refs.snackbar,
            })
        ).data || []


        if (!parent_id || (parent_id && parent_id === this.items.id)) {
          this.currentItem = this.items
          this.currentPath = this.items.path
        } else {
          this.currentItem = this.findElementById(this.items.children, parent_id)
          this.currentPath = this.currentItem.path
        }
      } finally {
        this.loading = false
      }
    },

    openMenu(event, {item}) {
      this.currentItem.children.forEach((i) => {
        if (i === item) {
          i.menu = true
          i.menu_x = event.pageX
          i.menu_y = event.pageY
        } else {
          i.menu = false
        }
      })
    },

    closeMenu(event, {item}) {
      setTimeout(() => {
        this.currentItem.children.forEach((i) => {
          i.menu = false
          i.menu_x = 0
          i.menu_y = 0
        })
      }, 10);
    },

    openItem(event, {item}) {
      if (item.id === '..') {
        this.navigateBack(item)
      } else if (item.file) {
        this.openfile(item.path)
      } else {
        this.navigateForward(item)
      }
    },

    navigateHome() {
      this.currentItem = this.items
      this.currentPath = this.items.path
    },

    navigateForward(item) {
      this.currentPath = item.path
      this.currentItem = item
    },

    async openfile(path) {
      var FileSaver = require('file-saver');

      const finfo = this.items = (await ApiService._get('file-manager/download', {
        params: {path: path},
        snackbar: this.$refs.dialog.$refs.snackbar,
      })).data

      FileSaver.saveAs(finfo.url, finfo.name)
    },

    navigateBack(item) {
      if (item.parent_id === this.items.id) {
        this.currentPath = this.items.path
        this.currentItem = this.items
      } else {
        this.currentItem = this.findElementById(this.items.children, item.parent_id)
        this.currentPath = this.currentItem.path
      }
    },

    findElementById(items, id) {
      let o;
      items.some(function iter(a) {
        if (a['id'] === id) {
          o = a;
          return true;
        }
        return Array.isArray(a.children) && a.children.some(iter);
      });
      return o;
    },

    onDrop(event) {
      event.preventDefault()

      this.dragover = false

      if (this.loading) {
        return
      }

      const files = event.dataTransfer.files

      for (let i = 0; i < files.length; i++) {
        this.files.push(files[i])
      }

      this.upload()
    },

    uploadFromInputfile($event) {
      if ($event.target.files.length < 1) {
        return
      }

      this.files = [...$event.target.files];

      this.$refs.inputfile.value = '';

      this.upload()
    },

    async upload() {
      try {
        this.loading = true

        await Promise.all(this.files.map((file) => {
          const formData = new FormData()
          formData.append('file', file)
          formData.append('path', this.currentPath)

          return ApiService._post('file-manager/upload', formData, {
            snackbar: this.$refs.dialog.$refs.snackbar,
          })
        }))

        this.files = []

        this.load(this.currentItem.id)
      } catch (e) {
        this.loading = false
      }
    },

    removeSystemFiles(items) {
      return items.filter(item => {
        // Filter out items with name '.S3_Storage'
        if (item.name === '.S3_Storage') {
          return false
        }

        // If the item has children, recursively filter them
        if (item.children) {
          item.children = this.removeSystemFiles(item.children);
        }

        return true;
      })
    },
  }
}
</script>

<style scoped>
>>> tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0);
  .theme--light.v-data-table {
    background-color: transparent;
  }
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.v-card--link:before {
  background: none;
}
</style>