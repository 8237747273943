import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("default",null,{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"loading":_vm.processing,"disabled":_vm.processing}},[_c(VCardTitle,[_vm._v(" Renomear ")]),_c(VCardText,[_c('my-text-field',{ref:"newName",attrs:{"suffix":_vm.file ? '.' + _vm.file : ''},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.createNewFolder.apply(null, arguments)}},model:{value:(_vm.newName),callback:function ($$v) {_vm.newName=$$v},expression:"newName"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.newName || _vm.newName === _vm.computedOldName,"depressed":""},on:{"click":_vm.createNewFolder}},[_vm._v(" Renomear ")])],1)],1),_c('custom-snackbar',{ref:"snackbar"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }