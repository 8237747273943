<template>
  <v-currency-field
      v-model="internalValue"
      :clearable="clearable"
      :decimalLength="decimalLength"
      dense
      :disabled="disabled"
      :error-messages="hideErrors ? [] : errorMessages"
      :label="label + (required ? ' *' : '')"
      locale="pt-BR"
      hide-details="auto"
      outlined
      persistent-placeholder
      :readonly="readonly"
      :prefix="prefix"
      reverse
      @focus="$event.target.select()"
  />
</template>

<script>
export default {
  name: 'MyCurrency',

  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    decimalLength: {
      type: Number,
      default: 2
    },
    disabled: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    prefix: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: null
    },
  },

  data () {
    return {
      hideErrors: false,
      internalValue: this.value
    }
  },

  watch: {
    value (newValue) {
      this.internalValue = newValue
    },

    internalValue (newValue) {
      this.errorMessages = []
      this.hideErrors = true
      this.$emit('input', newValue)
    },

    errorMessages () {
      this.hideErrors = false
    }
  },
}
</script>