<template>
  <my-list-card
    :headers="headers"
    resource="projeto-pesquisa/acompanhamento/follow-up"
    title="Acompanhamento de Projetos"
    show-detail
    show-edit-action
    :loading="loading"
  >
    <template #item.show="{ item }">
      <pesquisa-acompanhamento-projeto-detail-dialog
        :itemId="item.id"
        hide-default-actions
      />
    </template>
    <template #item.situation.description="{ item }">
      <v-chip
        class="font-weight-medium"
        :color="item.situation.color"
        dark
        label
        small
      >
        {{ item.situation.description }}
      </v-chip>
    </template>
    <template #item.tsp.situation.description="{ item }">
      <v-chip
        v-if="item.tsp"
        class="font-weight-medium"
        :color="item.tsp ? item.tsp.situation.color : 'grey'"
        dark
        label
        small
      >
        {{ item.tsp ? item.tsp.situation.description : 'Pendente' }}
      </v-chip>
    </template>
    <template #item.tap.situation.description="{ item }">
      <v-chip
        v-if="item.tap"
        class="font-weight-medium"
        :color="item.tap ? item.tap.situation.color : 'grey'"
        dark
        label
        small
      >
        {{ item.tap ? item.tap.situation.description : 'Pendente' }}
      </v-chip>
    </template>
    <template #item.issued_at="{ item }">
      {{ item.issued_at | date }}
    </template>
    <template #item.actions="{ item, resource }">
      <pesquisa-acompanhamento-encerramento-form-dialog
          :disabled="disableEditAction(item)||item.situation.id <= 2"
          :item-id="item.id"
          @success="updateItemSituation($event, item)"
      />
      <file-manager
        :folder="item.fm_path"
        repository="research-projects"
        @success="updateItemSituation($event, item)"
      />
    </template>
  </my-list-card>
</template>

<script>
import MyListCard from "@/view/components/MyListCard.vue"
import MyActionConfirmation from "@/view/components/MyActionConfirmation.vue"
import PesquisaSolicitacaoProjetoDetailDialog
  from "@/view/pages/projeto-pesquisa/solicitacao/Components/DetailDialog.vue"
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module"
import PesquisaAcompanhamentoEncerramentoFormDialog
  from "@/view/pages/projeto-pesquisa/acompanhamento/Components/EncerramentoFormDialog.vue"
import _ from "lodash"
import PesquisaAcompanhamentoProjetoDetailDialog
  from "@/view/pages/projeto-pesquisa/acompanhamento/Components/DetailDialog.vue";
import FileManager from "@/view/components/FileManager/Main.vue";

export default {
  components: {
    FileManager,
    PesquisaAcompanhamentoProjetoDetailDialog,
    PesquisaAcompanhamentoEncerramentoFormDialog,
    PesquisaSolicitacaoProjetoDetailDialog,
    MyActionConfirmation,
    MyListCard
  },
  data: () => ({
    loading: false,
    headers: [{
      align: 'center',
      text: 'Status Projeto',
      value: 'situation.description',
      width: 0,
    }, {
      align: 'center',
      text: 'Status TSP',
      value: 'tsp.situation.description',
      width: 0,
    }, {
      align: 'center',
      text: 'Status TAP',
      value: 'tap.situation.description',
      width: 0,
    }, {
      text: 'Nome do Projeto',
      value: 'project_name',
    }, {
      text: 'Solicitante',
      value: 'owner_name',
    }, {
      text: 'Parceiro do Projeto',
      value: 'partner_name',
    }, {
      align: 'center',
      text: 'Data Solicitação',
      value: 'issued_at',
      width: 0,
    }, {
      value: 'actions',
      align: 'center',
      width: 116,
    }]
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Projetos' },
      { title: 'Acompanhamento dos Projetos' },
    ])
  },
  methods: {
    disableEditAction(item) {
      return item.situation.id == 6 || item.situation.id == 7 || (item.tsp.situation.id == 4) || (item.tap && item.tap.situation.id == 4)
    },

    async updateItemSituation(event, item) {
      item.situation = _.clone(event.situation)
    },
  }
}
</script>