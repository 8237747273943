<template>
  <my-detail-dialog
    :hide-default-actions="hideDefaultActions"
    :resource="`projeto-pesquisa/acompanhamento/${itemId}`"
    :title="`Detalhes do Projeto #${itemId}`"
    :width="1024"
    hide-default-actions
  >
    <template v-slot:title="{ item }">
      <v-chip
          class="ml-2"
          :color="item && item.situation ? item.situation.color : ''"
          dark
          label
          small
      >
        {{ item && item.situation ? item.situation.description : ''}}
      </v-chip>
    </template>
    <template v-slot:body="{ item }">
      <v-card-title>
        Dados do Projeto
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="9">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Nome
              </v-col>
              <v-col cols="12">
                {{ item.project_name }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="3">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Situação
              </v-col>
              <v-col cols="12">
                {{ item.situation ? item.situation.description : ''}}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>
        Solicitante
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="5">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Nome
              </v-col>
              <v-col cols="12">
                {{ item.owner_name }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="3">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Cargo
              </v-col>
              <v-col cols="12">
                {{ item.owner_position }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="4">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                E-mail
              </v-col>
              <v-col cols="12">
                {{ item.owner_email }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>
        Parceiro
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Nome
              </v-col>
              <v-col cols="12">
                {{ item.partner_name }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Contato
              </v-col>
              <v-col cols="12">
                {{ item.partner_contact }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="2">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Telefone
              </v-col>
              <v-col cols="12">
                {{ item.partner_phone }}
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="4">
            <v-row dense>
              <v-col cols="12" class="subtitle-2">
                Site
              </v-col>
              <v-col cols="12">
                {{ item.partner_site }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-row dense>
        <v-col cols="12" md="6" class="px-3">
          <v-card-title>
            TAP
            <v-chip
                class="ml-2"
                :color="item.tap? item.tap.situation.color : ''"
                dark
                label
                small
            >
              {{ item.tap? item.tap.situation.description : '' }}
            </v-chip>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="subtitle-2">
                Justificativa Técninca
              </v-col>
              <v-col cols="12">
                {{ item.tap ? item.tap.technical_justification : '' }}
              </v-col>
              <v-col cols="12" class="subtitle-2">
                Objetivo
              </v-col>
              <v-col cols="12">
                {{ item.tap ? item.tap.commercial_justification : '' }}
              </v-col>
              <v-col cols="12" class="subtitle-2">
                Informações Adicionais
              </v-col>
              <v-col cols="12">
                {{ item.tap ? item.tap.additional_information : '' }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
        <v-col cols="12" md="6" class="px-3">
          <v-card-title>
            TSP
            <v-chip
                class="ml-2"
                :color="item.tsp? item.tsp.situation.color : ''"
                dark
                label
                small
            >
              {{ item.tsp? item.tsp.situation.description : ''}}
            </v-chip>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="subtitle-2">
                Descrição
              </v-col>
              <v-col cols="12">
                {{ item.tsp? item.tsp.description : '' }}
              </v-col>
              <v-col cols="12" class="subtitle-2">
                Objetivo
              </v-col>
              <v-col cols="12">
                {{ item.tsp? item.tsp.goal : '' }}
              </v-col>
              <v-col cols="12" class="subtitle-2">
                Informações Adicionais
              </v-col>
              <v-col cols="12">
                {{ item.tsp? item.tsp.additional_information : '' }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-col>
      </v-row>
      <v-card-title>
        Cronograma
      </v-card-title>
      <v-card-text>
        <v-card outlined>
          <v-data-table
              :headers="[{
            text: 'Descrição',
            value: 'description',
          }, {
            text: 'Início',
            value: 'forecast_start_at',
            align: 'center',
            width: 0,
          }, {
            text: 'Término',
            value: 'forecast_end_at',
            align: 'center',
            width: 0,
          }, {
            text: 'Orçado',
            value: 'budget_value',
            width: 125,
          }]"
              hide-default-footer
              :items="item.steps"
              outlined
          >
            <template #item.forecast_start_at="{ item }">
              {{ item.forecast_start_at | date }}
            </template>
            <template #item.forecast_end_at="{ item }">
              {{ item.forecast_end_at | date }}
            </template>
            <template #item.budget_value="{ item }">
              <v-row dense no-gutters>
                <v-col cols="auto">
                  <span>R$</span>
                </v-col>
                <v-col class="text-right">
                  <span>{{ item.budget_value | formatNumber }}</span>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <v-card-title>
        Execução
      </v-card-title>
      <v-card-text>
        <v-card outlined>
          <v-data-table
            :headers="[{
              text: 'Descrição',
              value: 'description',
            }, {
              text: 'Início',
              value: 'started_at',
              width: 0,
            }, {
              text: 'Término',
              value: 'ended_at',
              width: 0,
            }, {
              text: 'Prazo',
              value: 'deadline',
              width: 0,
            }, {
              text: 'Realizado',
              value: 'actual_value',
              width: 125,
            }, {
              text: 'R$ Orcado x Real.',
              value: 'saving',
              width: 125
            }]"
            hide-default-footer
            :items="item.steps"
            outlined
          >
            <template #item.started_at="{ item }">
              {{ item.started_at | date }}
            </template>
            <template #item.ended_at="{ item }">
              {{ item.ended_at | date }}
            </template>
            <template #item.deadline="{ item }">
              <v-chip label :color="deadline(item).color" dark small>
                    <span class="font-weight-medium">
                      {{ deadline(item).text }}
                    </span>
              </v-chip>
            </template>
            <template #item.actual_value="{ item }">
              <v-row if="item.actual_value" dense no-gutters>
                <v-col cols="auto">
                  <span>R$</span>
                </v-col>
                <v-col class="text-right">
                  <span>{{ item.actual_value | formatNumber }}</span>
                </v-col>
              </v-row>
            </template>
            <template #item.saving="{ item }">
              <v-row v-if="item.actual_value" dense no-gutters>
                <v-col cols="auto">
                  <span class="font-weight-medium" :class="`${getSavingValue(item).color}--text`">R$</span>
                </v-col>
                <v-col class="text-right font-weight-medium" :class="`${getSavingValue(item).color}--text`">
                  <span>{{ getSavingValue(item).value | formatNumber }}</span>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card>
      </v-card-text>
      <template v-if="item.closing_justification ">
        <v-card-title>
          Considerações Finais
        </v-card-title>
        <v-card-text>
          {{ item.closing_justification }}
        </v-card-text>
      </template>
    </template>
  </my-detail-dialog>
</template>
<script>
import MyDetailDialog from '@/view/components/MyDetailDialog.vue'
import moment from 'moment'
import MyCurrency from "@/view/components/MyCurrency.vue";
export default {
  name: "PesquisaAcompanhamentoProjetoDetailDialog",

  components: {MyCurrency, MyDetailDialog},

  props: {
    itemId: {
      type: [Number, String],
      required: true,
    },
    hideDefaultActions: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    deadline(item) {
      if (item.ended_at) {
        const diffDays = moment(item.forecast_end_at).diff(moment(item.ended_at), 'days');

        if (diffDays < 0) return { text: 'Atrasado', color: 'red' }
        return { text: 'No prazo', color: 'green' }
      } else {
        const diffDays = moment(item.forecast_end_at).diff(moment(moment().format('YYYY-MM-DD')), 'days');

        if (diffDays < 0) return { text: 'Atrasado', color: 'red' }
        if (diffDays === 0) return { text: 'Hoje', color: 'amber' }
        if (diffDays === 1) return { text: 'Amanhã', color: 'orange' }
        return { text: `${diffDays} dias`, color: 'green' }
      }
    },

    getSavingValue(item) {
      const value =  item.budget_value - item.actual_value;

      if (value < 0) return { value: value * -1, color: 'red' }
      if (value >= 0) return { value: value, color: 'green' }
    },
  },
}
</script>