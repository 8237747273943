<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="350"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs"></slot>
    </template>
    <v-card :loading="processing" :disabled="processing">
      <v-card-title>
        Renomear
      </v-card-title>
      <v-card-text>
        <my-text-field
          v-model="newName"
          ref="newName"
          :suffix="file ? '.' + file : ''"
          @keydown.native.enter="createNewFolder"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
            text
            @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
            color="primary"
            :disabled="!newName || newName === computedOldName"
            depressed
            @click="createNewFolder"
        >
          Renomear
        </v-btn>
      </v-card-actions>
    </v-card>
    <custom-snackbar ref="snackbar" />
  </v-dialog>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import MyTextField from "@/view/components/MyTextField.vue";

export default {
  name: "FileManagerRename",

  components: {MyTextField},

  props: {
    path: {
      type: String,
      required: true,
    },
    from: {
      type: String,
      required: true,
    },
    file: {
      type: String,
      required: true,
    },
    oldName: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    processing: false,
    newName: null,
  }),

  computed: {
    computedOldName () {
      if (this.file) {
        return this.oldName.split('.').slice(0, -1).join('.')
      }

      return this.oldName
    }
  },

  watch: {
    dialog (value) {
      if (!value) {
        this.$emit('closeMenu')
      } else {
        this.newName = this.computedOldName

        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.newName.select()
          })
        })
      }
    }
  },

  methods: {
    async createNewFolder () {
      if (this.processing) {
        return
      }

      if (this.newName === this.computedOldName) {
        return
      }

      try {
        this.processing = true

        await ApiService._put('file-manager', {
          path: this.path,
          from: this.from,
          to: this.path + '/' + this.newName + (this.file ? `.${this.file}` : ''),
        }, {
          snackbar: this.$refs.snackbar
        })

        this.$emit('success')

        this.newName = null

        this.dialog = false
      } finally {
        this.processing = false
      }
    },
  },
}
</script>