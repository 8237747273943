import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":_vm.width,"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onDialog = ref.on;
return [_c(VTooltip,{attrs:{"left":_vm.tooltipLeft,"right":_vm.tooltipRight||!_vm.tooltipLeft&&!_vm.tooltipRight},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTootip = ref.on;
return [_c(VBtn,_vm._g({attrs:{"disabled":_vm.disabled,"icon":"","small":""}},Object.assign({}, onDialog, onTootip)),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.tooltip)+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.title)+" "),_vm._t("title",null,{"item":_vm.item}),_c(VSpacer),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],2),_vm._t("body",null,{"item":_vm.item}),(!_vm.hideDefaultActions)?_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c(VBtn,{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Salvar ")]),_c(VSpacer)],1):_vm._e()],2),_c('custom-snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }