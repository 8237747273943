import { VChip } from 'vuetify/lib/components/VChip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('my-list-card',{attrs:{"headers":_vm.headers,"resource":"projeto-pesquisa/acompanhamento/follow-up","title":"Acompanhamento de Projetos","show-detail":"","show-edit-action":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.show",fn:function(ref){
var item = ref.item;
return [_c('pesquisa-acompanhamento-projeto-detail-dialog',{attrs:{"itemId":item.id,"hide-default-actions":""}})]}},{key:"item.situation.description",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"font-weight-medium",attrs:{"color":item.situation.color,"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.situation.description)+" ")])]}},{key:"item.tsp.situation.description",fn:function(ref){
var item = ref.item;
return [(item.tsp)?_c(VChip,{staticClass:"font-weight-medium",attrs:{"color":item.tsp ? item.tsp.situation.color : 'grey',"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.tsp ? item.tsp.situation.description : 'Pendente')+" ")]):_vm._e()]}},{key:"item.tap.situation.description",fn:function(ref){
var item = ref.item;
return [(item.tap)?_c(VChip,{staticClass:"font-weight-medium",attrs:{"color":item.tap ? item.tap.situation.color : 'grey',"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(item.tap ? item.tap.situation.description : 'Pendente')+" ")]):_vm._e()]}},{key:"item.issued_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.issued_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var resource = ref.resource;
return [_c('pesquisa-acompanhamento-encerramento-form-dialog',{attrs:{"disabled":_vm.disableEditAction(item)||item.situation.id <= 2,"item-id":item.id},on:{"success":function($event){return _vm.updateItemSituation($event, item)}}}),_c('file-manager',{attrs:{"folder":item.fm_path,"repository":"research-projects"},on:{"success":function($event){return _vm.updateItemSituation($event, item)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }